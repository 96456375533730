<template>
  <div>
    <vs-button
      v-if="changed"
      color="warning"
      type="border"
      class="btn-view-changes"
      @click="showModalAlteracoes = true"
    >
      <i class="onpoint-clock-counter-clockwise icon-font pr-3"></i> Ver alterações
    </vs-button>

    <vs-popup
      title="Alterações do endosso"
      :active.sync="showModalAlteracoes"
      id="modal-alteracoes"
    >
      <vs-row v-if="!!responsavel" class="mb-8">
        <p class="w-full">
          <span class="font-semibold">Data da alteração: </span
          >{{ $utils.dateToDDMMYYYYHHmm(responsavel.ModifiedAt) }}
        </p>
        <p class="w-full">
          <span class="font-semibold">Alterado por: </span
          >{{ responsavel.UserName }}
        </p>
      </vs-row>

      <vs-row>
        <vs-col
          vs-lg="6"
          vs-sm="12"
          v-for="(text, indexText) in [
            'Originalmente na apólice:',
            'O que foi alterado:'
          ]"
          :key="indexText"
        >
          <p
            class="w-full font-semibold text-primary"
            :class="{ 'md:pr-5': indexText == 0, 'md:pl-5': indexText == 1 }"
          >
            {{ text }}
          </p>

          <div
            v-for="(item, index) in objectDiff"
            :key="index"
            :class="{ 'pr-5': indexText == 0, 'pl-5': indexText == 1 }"
            class="container-changed w-full"
            vs-lg="6"
            vs-sm="12"
          >
            <span>
              <p class="w-full font-semibold">
                {{ item.label }}
              </p>

              <p
                class="w-full font-semibold"
                :class="{
                  'text-warning': indexText == 0,
                  'text-primary': indexText == 1
                }"
                v-html="indexText == 0 ? item.origin : item.current"
              ></p>
            </span>
          </div>
        </vs-col>

        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          v-if="showDesfazer"
        >
          <vs-button type="border" class="btn" @click="showModalConfirm = true">
            <i class="onpoint-arrow-counter-clockwise icon-font pr-3"></i>
            Reverter alterações
          </vs-button>
        </vs-col>
      </vs-row>

      <vs-popup
        class="popup-confirm"
        title="Deseja realmente desfazer às alterações?"
        :active.sync="showModalConfirm"
      >
        <div class="vw-row mt-3">
          <div class="vx-col w-full text-right">
            <vs-button
              @click="showModalConfirm = false"
              class="button-default mr-5"
            >
              Cancelar
            </vs-button>
            <vs-button @click="desfazerAlteracao()" class="button-primary">
              Confirmar
            </vs-button>
          </div>
        </div>
      </vs-popup>
    </vs-popup>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "visualizar-alteracoes",
  props: {
    showDesfazer: { type: Boolean, default: true },
    changed: { type: Boolean, default: false },
    objectDiff: { type: Array, default: () => [] },
    responsavel: {
      type: Object
    }
  },
  data() {
    return {
      showModalAlteracoes: false,
      showModalConfirm: false
    };
  },
  methods: {
    ...mapMutations("endosso-module", ["reseteEndosso"]),

    desfazerAlteracao() {
      // this.reseteEndosso();
      window.location.reload();
      this.showModalConfirm = false;
      this.showModalAlteracoes = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-view-changes {
  background: rgba(var(--vs-warning), 0.08) !important;
  height: fit-content;
  padding: 5px 10px !important;
  font-size: 12px;

  &:hover {
    background: rgba(var(--vs-warning), 0.2) !important;
  }
}

.container-changed {
  padding-bottom: 15px;
  span {
    display: block;
    background: #f8f8f8;
    border-radius: 6px;
    padding: 15px;
  }

  .btn-undo {
    border: none;
    background: transparent;
    box-shadow: 0 0;
    float: right;
    color: #626262;
  }
}

@media (max-width: 900px) {
  .container-btn-changes {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &:first-of-type {
      padding-top: 5px;
      order: 2;
    }
    &:last-of-type {
      padding-bottom: 5px;
      order: 1;
    }
  }
}

.icon-font::before{
  position: relative;
  top: 2px;
}
</style>

<style lang="scss">
#modal-alteracoes {
  .con-vs-popup {
    .popup-confirm {
      z-index: auto;

      .vs-popup {
        width: 900px !important;
        height: 90%;
      }
    }
  }

  .con-vs-popup .vs-popup--content {
    overflow: auto !important;
  }
}
</style>
